body {
    background-color: #fff;
    color: #000;
}

.page-container {
    max-width: 1000px;
}

.page-container-smaller {
    max-width: 600px;
}

.button {
    background-color: #77C5D8;
    color: #303030;
    min-width: 120px;
    text-transform: uppercase;
    font-size: smaller;
    border-radius: 4px;
    border: none;
    padding: 8px;
    font-weight: bold;
    cursor: pointer;
}

.description {
    color: rgb(53, 53, 53);
}

.button:hover {
    background-color: #4fb4cd;
    color: #303030;
}

.button:disabled {
    background-color: lightgray;
    color: white;
}

.form-control,
.form-select {
    border: 1px solid black;
    border-radius: 0px;
}

.delivery_icon {
    max-width: 55px;
    vertical-align: top;
    margin-top: 4px;
    padding-left: 17px;
    padding-right: 17px;
}

.delivery-description {
    margin-left: 55px !important;
}

.payment_icon {
    max-width: 60px;
    vertical-align: top;
    padding-right: 5px;
}

.payment-description {
    margin-left: 60px !important;
}

/*checkboxes and radio-buttons*/

input[type="checkbox"],
input[type="radio"] {
    margin-top: 6px;
}

.form-check-input:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow:none;
}

.payment-names {
    width: 100%;
}